<template>
  <div>
    <h1>Nhập</h1>
    <form @submit.prevent="submitForm" class="form">
      <label for="description">Ghi chú:</label>
      <input type="text" id="description" v-model="description" required />

      <label for="image">Hình:</label>
      <input type="file" id="image" @change="handleFileChange" accept="image/*" capture="camera" required />

      <button type="submit" :disabled="loading">
        <span v-if="loading">Đang gửi...</span>
        <span v-else>Lưu</span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
const api = axios.create({
  baseURL: 'https://tpapi.minhnhan.in/api/',
});

export default {
  data() {
    return {
      description: '',
      image: null,
      location: '',
      timestamp: '',
      selectedFile: null,
    };
  },
  mounted() {
    // Fetch the current location and set it in the 'location' field
    this.getCurrentLocation();

    // Set the current timestamp in the 'timestamp' field
    this.setTimestamp();
  },
  methods: {
    async submitForm() {
      try {
        // Show loading state
        this.loading = true;

        // Step 1: Upload resized image using /api/upload
        const formData = new FormData();
        const resizedImage = await this.resizeImage(this.selectedFile);
        formData.append('image', resizedImage);

        // Make the image upload request
        const uploadResponse = await api.post('/upload', formData);

        // Extract the imagePath from the response
        const imagePath = uploadResponse.data.imagePath;

        // Step 2: Submit the form using /api/forms
        const formPayload = {
          description: this.description,
          location: this.location,
          timestamp: this.timestamp,
          username: Cookies.get('username'),
          image: imagePath, // Use the imagePath obtained from the image upload response
        };

        // Make the form submission request
        const formSubmitResponse = await api.post('/forms', formPayload);

        // Handle the form submission response as needed
        console.log('Form submitted successfully:', formSubmitResponse.data);

        // Navigate to the home page upon successful form submission
        this.$router.push('/home');

        // Hide loading state
        this.loading = false;
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle errors as needed
      }
    },
    handleFileChange(event) {
      // Update the selected file when the input value changes
      this.selectedFile = event.target.files[0];
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Set the 'location' field with the current coordinates
            this.location = `${position.coords.latitude}, ${position.coords.longitude}`;
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    },
    setTimestamp() {
      // Set the 'timestamp' field with the current timestamp
      this.timestamp = new Date().toISOString();
    },
    async resizeImage(image) {
      // Resize the image using a canvas
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(image);

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxWidth = 1024;

          const scaleFactor = maxWidth / img.width;
          canvas.width = maxWidth;
          canvas.height = img.height * scaleFactor;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], `${Date.now()}.jpg`, { type: 'image/jpeg' }));
          }, 'image/jpeg');
        };
      });
    },
  },
};
</script>

<style scoped>
/* Add component-specific styles */
.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

/* Responsive styles using media queries */
@media (max-width: 600px) {
  .form {
    max-width: 100%;
  }
}

/* Add a top margin of 64px to the submit button */
button {
  margin-top: 64px;
}
</style>
