import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from '../views/LoginForm.vue';
import InputForm from '../views/InputForm.vue';
import HomeView from '../views/HomeView.vue';
import Cookies from 'js-cookie';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/login',
      component: LoginForm,
    },
    {
      path: '/input-form',
      component: InputForm,
    },
    {
      path: '/home',
      component: HomeView,
    },
    // Add a catch-all route for unknown paths, redirect to the default route
    {
      path: '/:catchAll(.*)',
      redirect: '/home', // Redirect to the login page or any other default route
    },
  ],
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  const accessToken = Cookies.get('accessToken');
  console.log(accessToken);

  // Check if the user is logged in, and allow access to the route
  if (accessToken || to.path === '/login') {
    next();
  } else {
    // Redirect to the login page if not logged in
    next('/login');
  }
});

export default router;
