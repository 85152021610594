<template>
    <div>
        <h1>Forms</h1>
   
        <button @click="openInputForm">Thêm</button>
        <button @click="signOut" style="margin-left: 32px;">Thoát</button>
        <ul>
            <li v-for="form in forms" :key="form.id" class="form-item" @click="openGoogleMaps(form.location)">
                <div class="image-container">
                    <img :src="getFullImageUrl(form.image)" alt="Form Image" />
                </div>
                <div class="form-details">
                    <p>{{ form.description }}</p>
                    <p>{{ formatTimestamp(form.timestamp) }}</p>
                </div>
            </li>
        </ul>
        <button v-if="showLoadMoreButton" @click="loadMore">Xem Thêm</button>
    </div>
</template>
  
<script>
import axios from 'axios';
import Cookies from 'js-cookie';
const api = axios.create({
    baseURL: 'https://tpapi.minhnhan.in/api/',
});
export default {
    data() {
        return {
            forms: [],
            currentPage: 0,
            itemsPerPage: 10,
            lastPage: false,
        };
    },
    computed: {
        showLoadMoreButton() {
            return !this.lastPage;
        },
    },
    mounted() {
        // Load forms when the component is mounted
        this.loadForms();
    },
    methods: {
        loadMore() {
            this.currentPage++;
            this.loadForms();
        },
        getFullImageUrl(relativePath) {
            // Assuming you have a base URL stored in a variable or retrieved dynamically
            const baseUrl = 'https://tpapi.minhnhan.in/'; // Replace with your actual base URL

            // Concatenate the base URL with the relative path
            return `${baseUrl}${relativePath}`;
        },
        openGoogleMaps(location) {
            // Extract latitude and longitude from the location field
            const [latitude, longitude] = location.split(',');

            // Construct the Google Maps link
            const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

            // Open the link in a new tab
            window.open(googleMapsLink, '_blank');
        },
        async loadForms() {
            try {
                // Get the username from the cookie
                const username = Cookies.get('username');

                // Make a GET request to fetch forms from the API filtered by username
                const response = await api.get('/forms', {
                    params: { username: username, limit: this.itemsPerPage, skip: this.currentPage * this.itemsPerPage },
                });

                // Update the forms data property with the fetched forms
                this.forms = this.forms.concat(response.data);

                if (response.data.length < this.itemsPerPage) {
                    // If the number of fetched forms is less than the itemsPerPage, it means we have reached the last page
                    this.lastPage = true;
                }
            } catch (error) {
                console.error('Error loading forms:', error);
            }
        },
        signOut() {
            // Remove the access token and username cookies
            Cookies.remove('accessToken');
            Cookies.remove('username');
            this.$router.push('/login');
        },
        openInputForm() {
            // Navigate to the input-form route when the "Add Form" button is clicked
            this.$router.push('/input-form');
        },
        formatTimestamp(timestamp) {
            const formattedDate = new Date(timestamp).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(/\//g, '-');
            const [month, day, year] = formattedDate.split('-');
            return `${day}/${month}/${year}`;
        },
    },
};
</script>
  
<style scoped>
.form-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.image-container {
    width: 320px;
    /* Adjust as needed */
    margin-right: 10px;
}

.image-container img {
    max-width: 100%;
    height: auto;
}

.form-details {
    flex-grow: 1;
}
</style>
