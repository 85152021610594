<!-- Login.vue -->
<template>
  <div>
    <h1>TPTool</h1>
    <form @submit.prevent="login">
      <label for="username">Tên:</label>
      <input v-model="username" type="text" id="username" required />

      <label for="password">Mật mã:</label>
      <input v-model="password" type="password" id="password" required />

      <button type="submit">Đăng nhập</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
const api = axios.create({
  baseURL: 'https://tpapi.minhnhan.in/api/',
});

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
      // Call your login API
      try {
        const response = await api.post(
          'login',
          {
            username: this.username,
            password: this.password,
          }
        );

        // Save token to cookie
        Cookies.set('accessToken', response.data.accessToken);
        Cookies.set('username', this.username);

        // Redirect to the input form
        this.$router.push('/home');
      } catch (error) {
        console.error('Login failed', error);
        // Show an error dialog using alert (replace this with your dialog/modal component)
        alert('Lỗi. Kiểm tra lại thông tin');
      }
    },
  },
};
</script>

<style scoped>
form {
  max-width: 400px;
  margin: auto;
}

input {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
}
</style>
